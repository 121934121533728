import React from "react";
import VideoComponent from "../VideoComponent";
import CustomLink from "../CustomLink";

const RowsSection = ({
  subHeading,
  heading,
  description,
  btnText,
  btnLink,
  imgSrc,
  videoSrc,
  imgAlt,
  isReverse,
  containerClasses,
  imageWidth,
  imageHeight,
  contentClasses,
}) => {
  return (
    <section
      className={`sm:mx-auto md:mx-0  w-auto sm:w-[644px] md:w-auto flex px-4 py-7 sm:p-10 lg:h-128 md:px-16 md:py-7 imageCardSection ${
        isReverse ? "md:flex-row-reverse" : "md:flex-row"
      } flex-col justify-between bg-inherit md:gap-x-16 gap-y-6 ${containerClasses}`}
    >
      <div className={`flex-1 text-left my-auto ${contentClasses}`}>
        <div
          className={`max-w-lg ${
            !isReverse && "md:ml-auto"
          } flex flex-col lg:self-center self-start space-y-4 sm:space-y-5 lg:mb-0 md:mb-20 mb-5 `}
        >
          <h3 className="text-base tracking-tightest font-medium uppercase text-gray-1200">
            {subHeading}
          </h3>
          <h4 className="text-gray-2700 text-2.5xl sm:text-2.5xl font-semibold leading-normal tracking-tighter_3 max-w-md">
            {heading}
          </h4>
          <p className="max-w-md text-base text-gray-600 font-normal">
            {description}
          </p>
          {btnLink && (
            <CustomLink
              href={btnLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-end sm:items-center justify-between w-full
              sm:max-w-auto font-bold text-purple-1500 max-w-fit"
            >
              <span>
                {btnText}
                <svg
                  className="sm:pb-0 pb-0.5 inline-block"
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.0259 8.28787L4.53439 13.3581C4.51743 13.3715 4.49705 13.3798 4.47558 13.382C4.45411 13.3843 4.43243 13.3805 4.41304 13.371C4.39364 13.3616 4.3773 13.3468 4.36591 13.3285C4.35452 13.3101 4.34853 13.289 4.34863 13.2674V12.1543C4.34863 12.0837 4.38175 12.016 4.43647 11.9728L9.62045 7.92499L4.43647 3.87715C4.38031 3.83395 4.34863 3.76627 4.34863 3.69571V2.5826C4.34863 2.48612 4.45951 2.43284 4.53439 2.49188L11.0259 7.56211C11.0811 7.60515 11.1257 7.6602 11.1564 7.72308C11.1871 7.78596 11.203 7.85501 11.203 7.92499C11.203 7.99496 11.1871 8.06401 11.1564 8.1269C11.1257 8.18978 11.0811 8.24483 11.0259 8.28787Z"
                    fill="#5E44FF"
                  />
                </svg>
              </span>
            </CustomLink>
          )}
        </div>
      </div>

      <div className="flex flex-1 justify-center">
        {videoSrc ? (
          <VideoComponent loop autoPlay muted className={"rounded-xl"}>
            <source
              src={videoSrc}
              type="video/mp4"
              className="w-full h-auto mx-auto"
            />
          </VideoComponent>
        ) : (
          <img
            src={imgSrc}
            width={imageWidth || 0}
            height={imageHeight || 0}
            alt={imgAlt || "img"}
            loading="lazy"
            className={`z-20 h-auto max-w-full ${
              imageHeight < 400 && imageWidth < 400 && "lg:px-0 px-5"
            }`}
          />
        )}
      </div>
    </section>
  );
};

export default RowsSection;

import React from "react";
import CustomLink from "../CustomLink";

const HeadingSection = ({
  imgSrc,
  imgAlt,
  imageWidth,
  imageHeight,
  labelText,
  subHeadingColor = "text-gray-1200 text-base",
  sectionHeading,
  sectionDescription,
  btnText,
  btnLink,
  headingClass="text-2.5xl text-gray-2700"
}) => {
  return (
    <>
      <div className="flex flex-col justify-center items-center">
        {imgSrc && (
          <img
            src={imgSrc}
            width={imageWidth || 0}
            height={imageHeight || 0}
            alt={imgAlt || "img"}
            loading="lazy"
            className="w-10 h-10"
          />
        )}
        <h2
          className={`font-medium text-center tracking-[0.8px] ${subHeadingColor} uppercase mt-2`}
        >
          {labelText}
        </h2>
      </div>
      <h3 className={`${headingClass}  sm:text-3.75xl sm:leading-11 leading-7 sm:tracking-tighter_3 tracking-tighter_1 font-semibold text-center`}>
        {sectionHeading}
      </h3>
      {sectionDescription && (
        <p className="text-base sm:text-xl sm:tracking-tighter_3 tracking-tighter_1 font-normal text-center text-gray-600 max-w-3xl">
          {sectionDescription}
        </p>
      )}
      <section className="flex justify-center">
        <CustomLink
          href={btnLink}
          target="_blank"
          rel="noopener noreferrer"
          className="button_gradient text-white btn h-12"
        >
          {btnText}
        </CustomLink>
      </section>
    </>
  );
};

export default HeadingSection;

import React from "react";
import NewHomeLeadFormWithAPIForm from "./NewHomeLeadFormWithAPI";

/**
 * This component renders a cta card in outlier detection encord active page
 * @param {object} data data from prismic
 * @param {boolean} isReverse to revert the flex-items
 * @param {string} className custom class
 * @author Waqas Ahmad
 * @returns JSX.Element
 */
const OutlinerFlexCard = ({
  heading,
  description,
  imageSRC,
  imageWidth,
  imageHeight,
  videoSRC,
  imageAlt,
  isReverse = false,
  className,
  location
}) => {
  return (
    <section
      className={`${className} flex md:max-h-[496px] ${
        isReverse ? "lg:flex-row-reverse" : "lg:flex-row"
      } flex-col justify-between shadow-lg rounded-3xl`}
    >
      <div className="flex flex-col self-start max-w-lg my-auto text-left">
        <h4
          className="text-[#262626] text-[28px] tracking-tighter_1 sm:text-2.5xl font-semibold mb-10"
          dangerouslySetInnerHTML={{
            __html: heading,
          }}
        />
        <div className="flex_card_description text-sm text-gray-600">
          {description}
        </div>
        <div className="mt-[18px] sm:mt-[26px]">
          <NewHomeLeadFormWithAPIForm
            location={location}
            btnText="Book a demo"
            isCustomBtnBg={true}
            btnColor="button_gradient border-0 px-8 h-[50px]"
          />
        </div>
      </div>
      <div className={`rounded-2xl 2xl:p-10 flex-1 flex `}>
        {" "}
        {videoSRC ? (
          <VideoComponent
            loop
            autoPlay
            muted
            className="w-full h-auto mx-auto rounded-2xl"
          >
            <source src={data?.section_video?.url} type="video/mp4" />
          </VideoComponent>
        ) : (
          <img
            src={imageSRC}
            width={imageWidth || 0}
            height={imageHeight || 0}
            alt={imageAlt || "img"}
            loading="lazy"
            className="w-full h-auto max-w-md max-h-full mx-auto rounded-2xl 2xl:p-10 lg:max-w-none"
          />
        )}
      </div>
    </section>
  );
};

export default OutlinerFlexCard;

import { graphql } from "gatsby";
import React from "react";
import { HealthcareHero } from "../assets/backgrounds";
import BottomVector from "../assets/outlinerBottomVector.svg";
import WhiteQuote from "../assets/outlinerPageWhiteQoute.svg";
import CustomLink from "../components/CustomLink";
import CustomerRating from "../components/CustomerRating";
import NewHomeLeadFormWithAPIForm from "../components/NewHomeLeadFormWithAPI";
import OutlinerFlexCard from "../components/OutlinerFlexCard";
import TrustedBrands from "../components/TrustedBrands";
import Layout from "../components/layout";
import HeadingSection from "../components/outlier_Pages";
import RowsSection from "../components/outlier_Pages/RowsSection";
import SEO from "../components/seo";
import "../styles/page/outlier_detection_encord_active.scss";
import { pickSlice } from "../utilities/helpers";
import VideoComponent from "../components/VideoComponent";
import LogoMarquee from "../components/LogoMarquee";

const OutlierDetectionActive = ({ location, data }) => {
  const uid = data?.prismicOutlierDetectionEncordActivePage?.uid;
  const trusted_brands_list_home_page =
    data.allPrismicTrustedBrandsLogos?.nodes?.find(
      (x) => x.uid === "home-page-logos"
    )?.data?.body[0];
  const trusted_brands_list = data.allPrismicTrustedBrandsLogos?.nodes?.find(
    (x) => x.uid === "outlier_detection_page_logos"
  )?.data?.body[0];
  const _data = data?.prismicOutlierDetectionEncordActivePage?.data || {};
  const [repeatable_section] = pickSlice(_data, "repeatable_section");
  const [reviews] = pickSlice(_data, "reviews");
  const [case_study_section] = pickSlice(_data, "case_study_section");

  return (
    <Layout mainClasses="">
      <article className="relative">
        <div className="absolute w-full h-80 lg:top-72 sm:top-64 top-[400px] sm:left-0 -left-11 -z-10">
          <HealthcareHero />
        </div>
        <section className="px-2 mx-auto max-w-7xl sm:px-0">
          <article className="px-5 pt-16 mx-auto max-w-7xl md:pt-32 xl:px-0 sm:px-6">
            <section className="flex flex-col max-w-4xl mx-auto mb-5 gap-y-8 lg:mt-0 mt-14 lg:px-0 sm:px-10">
              <div
                dangerouslySetInnerHTML={{ __html: _data?.page_heading.html }}
                className="h1_gradient_accelerate text-2.5xl sm:text-3.25xl lg:leading-tight tracking-tighter_3 lg:text-4.5xl font-semibold text-purple-1000 text-center "
              />

              <p className="max-w-3xl mx-auto text-base text-center text-purple-1000 lg:text-xl">
                {_data?.page_description?.text}
              </p>
              <section className="flex justify-center">
                <NewHomeLeadFormWithAPIForm
                  location={location}
                  pageName="Outlier Detection with Encord Active Page"
                  btnText="Book a demo"
                  isCustomBtnBg={true}
                  btnColor="button_gradient border-0 px-8 h-[50px]"
                />
              </section>
            </section>
          </article>
          <article className="px-1 lg:px-0 sm:px-6">
            {_data?.hero_video ? (
              <VideoComponent
                loop
                autoPlay
                muted
                className={`mx-auto w-full h-auto max-h-[600px] rounded-xl`}
              >
                <source src={_data?.hero_video?.url} type="video/mp4" />
              </VideoComponent>
            ) : (
              <>
                {_data?.hero_image ? (
                  <img
                    src={_data?.hero_image?.url}
                    loading="eager"
                    width={_data?.hero_image?.dimensions?.width || 0}
                    height={_data?.hero_image?.dimensions?.height || 0}
                    alt={
                      _data?.hero_image?.alt ||
                      `${_data?.page_heading?.text} image`
                    }
                    className={`w-full max-h-[600px] -mx-3"`}
                  />
                ) : (
                  <section className="mt-20 2xl:mt-28 w-full h-[512px] bg-gray-200" />
                )}
              </>
            )}
          </article>
        </section>

        <section className="lg:my-16 sm:mt-9 sm:mb-16 mb-9">
          {uid === "data-curation" ? (
            <div>
              <div className="img_grayscale_opacity">
                <p
                  className={`text-center uppercase sm:text-sm text-2xs text-gray-1300 tracking-[0.07em]`}
                >
                  {trusted_brands_list_home_page?.primary?.label?.text}
                </p>
              </div>
              <LogoMarquee logoArray={trusted_brands_list_home_page} />
            </div>
          ) : (
            <TrustedBrands
              trusted_brands_list={trusted_brands_list}
              textStyle="text-xs sm:text-base lg:text-lg"
              widthClass="brands_width"
            />
          )}
        </section>

        <section className="max-w-screen bg-gray-2600">
          <section className="mx-auto text-center max-w-7xl">
            <section className="mb-5 md:mb-12 pt-12 sm:pt-16 lg:pt-18 pb-5 md:pb-0 flex flex-col justify-center items-center gap-4 md:gap-7 px-2 w-auto sm:w-[623px] mx-auto md:w-auto">
              <HeadingSection
                imgAlt={repeatable_section?.primary?.label_icon?.alt}
                imgSrc={repeatable_section?.primary?.label_icon?.url}
                imageWidth={
                  repeatable_section?.primary?.label_icon?.dimensions?.width
                }
                imageHeight={
                  repeatable_section?.primary?.label_icon?.dimensions?.height
                }
                labelText={repeatable_section?.primary?.section_label?.text}
                sectionHeading={
                  repeatable_section?.primary?.section_heading?.text
                }
                sectionDescription={
                  repeatable_section?.primary?.section_description?.text
                }
                btnText={repeatable_section?.primary?.button_text?.text}
                btnLink={repeatable_section?.primary?.button_link?.url}
                headingClass="text-2.25xl text-gray-2500"
                subHeadingColor="text-sm text-gray-1200"
              />
            </section>

            <section className="flex flex-col gap-5 px-5 pb-18 md:pb-24">
              {repeatable_section?.items?.map((item, index) => {
                const isReverse = index % 2 === 0;
                const fullBg = index === 4;
                const centerContent = index === 1;
                return (
                  <RowsSection
                    key={item?.id}
                    isReverse={isReverse}
                    btnLink={item?.learn_more_link?.url}
                    btnText={item?.learn_more_text?.text}
                    subHeading={item?.section_label?.text}
                    description={item?.section_description?.text}
                    heading={item?.section_heading?.text}
                    imgAlt={item?.section_image?.alt}
                    imgSrc={item?.section_image?.url}
                    videoSrc={item?.section_video?.url}
                    imageWidth={item?.section_image?.dimensions?.width}
                    imageHeight={item?.section_image?.dimensions?.height}
                    containerClasses={fullBg && "full-bleed-light_purple"}
                    contentClasses={centerContent && " lg:self-center"}
                  />
                );
              })}
            </section>
          </section>
        </section>

        <section className="relative">
          <div className="absolute z-10 transform -translate-x-1/2 -translate-y-1/2 review_bg top-1/2 left-1/2" />
          <article className="px-2.5 mx-auto max-w-7xl lg:px-0 sm:px-12 sm:py-16 py-11 rating_margin relative">
            <CustomerRating
              reviews={reviews}
              buttonText={"Book a demo"}
              cardBg={"customer_rating_card"}
              buttonClass="button_gradient"
            />
          </article>
        </section>

        <section className="pb-24 bg-gray-2600 pt-18">
          <section className="flex flex-col items-center justify-center gap-4 px-5 sm:px-0 mb-14 md:gap-7">
            <HeadingSection
              subHeadingColor={"text-purple-1500"}
              imgAlt={case_study_section?.primary?.label_icon?.alt}
              imgSrc={case_study_section?.primary?.label_icon?.url}
              imageWidth={
                case_study_section?.primary?.label_icon?.dimensions?.width
              }
              imageHeight={
                case_study_section?.primary?.label_icon?.dimensions?.height
              }
              labelText={case_study_section?.primary?.section_label?.text}
              sectionHeading={
                case_study_section?.primary?.section_heading?.text
              }
              sectionDescription={
                case_study_section?.primary?.section_description?.text
              }
              btnText={case_study_section?.primary?.button_text?.text}
              btnLink={case_study_section?.primary?.button_link?.url}
            />
          </section>

          <article className="px-5 mx-auto text-center md:px-28 max-w-7xl">
            <section className="flex flex-col text-left gap-14 md:flex-row md:justify-between md:items-center sm:px-11 rounded-2.5xl">
              <div className="flex flex-col md:max-w-[445px] gap-10">
                {case_study_section?.items?.map((item, index) => {
                  const { section_description, section_heading } = item || {};
                  return (
                    <div
                      key={index}
                      className="flex flex-col space-y-3 text-left"
                    >
                      <h1 className="text-xl sm:text-2.5xl text-gray-2500 font-semibold">
                        {section_heading?.text}
                      </h1>
                      <span className="text-base font-normal text-gray-600 sm:text-xl">
                        {section_description?.text}
                      </span>
                    </div>
                  );
                })}
              </div>
              <section
                className={`cardBg md:w-[460px] lg:w-auto xl:w-[460px] shadow-lg rounded-2.5xl p-5 sm:p-7 lg:mb-6 mb-4 border border-white`}
              >
                <section className="flex items-center justify-between">
                  <div className="flex gap-3">
                    <img
                      src={case_study_section?.primary?.card_icon?.url}
                      width={
                        case_study_section?.primary?.card_icon?.dimensions
                          ?.width || 0
                      }
                      height={
                        case_study_section?.primary?.card_icon?.dimensions
                          ?.height || 0
                      }
                      loading="lazy"
                      alt={
                        case_study_section?.primary?.card_icon?.alt ||
                        "card_icon"
                      }
                      className="w-44 h-11"
                    />
                  </div>
                  <img src={WhiteQuote} alt="white-quote" />
                </section>
                {case_study_section?.items?.map((item, index) => {
                  const { card_label_icon, card_label_text } = item || {};
                  return (
                    <>
                      <div className="mt-6 highlightedText">
                        <img
                          src={card_label_icon?.url}
                          width={card_label_icon?.dimensions?.width || 0}
                          height={card_label_icon?.dimensions?.height || 0}
                          loading="lazy"
                          alt={card_label_icon?.alt || "card_icon"}
                          className="w-5 h-5"
                        />
                        <p className="text-xl font-medium leading-normal text-purple-1500">
                          {card_label_text?.text}
                        </p>
                      </div>
                    </>
                  );
                })}

                <p className="py-6 text-base font-normal leading-7 text-gray-600">
                  {case_study_section?.primary?.card_description?.text}
                </p>
                <CustomLink
                  href={case_study_section?.primary?.button_link?.url}
                  target="_blank"
                  className="flex items-center font-bold text-purple-1500 w-max"
                >
                  <span>{case_study_section?.primary?.button_text?.text}</span>
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.0259 8.28787L4.53439 13.3581C4.51743 13.3715 4.49705 13.3798 4.47558 13.382C4.45411 13.3843 4.43243 13.3805 4.41304 13.371C4.39364 13.3616 4.3773 13.3468 4.36591 13.3285C4.35452 13.3101 4.34853 13.289 4.34863 13.2674V12.1543C4.34863 12.0837 4.38175 12.016 4.43647 11.9728L9.62045 7.92499L4.43647 3.87715C4.38031 3.83395 4.34863 3.76627 4.34863 3.69571V2.5826C4.34863 2.48612 4.45951 2.43284 4.53439 2.49188L11.0259 7.56211C11.0811 7.60515 11.1257 7.6602 11.1564 7.72308C11.1871 7.78596 11.203 7.85501 11.203 7.92499C11.203 7.99496 11.1871 8.06401 11.1564 8.1269C11.1257 8.18978 11.0811 8.24483 11.0259 8.28787Z"
                      fill="#5E44FF"
                    />
                  </svg>
                </CustomLink>
              </section>
            </section>
          </article>
        </section>
      </article>

      <section className="relative flex_card_dark px-2 lg:h-[707px] lg:flex justify-center items-center overflow-hidden">
        <div className="absolute z-0 transform -translate-y-1/2 top-1/2 lg:top-1/2 -left-52 lg:left-128 outliner_bottom_bg -translate-x-2/3" />
        <div className="absolute left-0 z-0 transform -translate-y-1/2 top-1/4 encord_top_bg -translate-x-2/3" />
        <img
          className="absolute left-0 z-10 hidden w-full md:block bottom-7"
          src={BottomVector}
          alt="bottom-vector"
        />
        <article className="relative z-10 px-2 mx-auto py-11 max-w-7xl sm:py-18 lg:px-0 sm:px-12">
          <OutlinerFlexCard
            heading={_data?.cta_heading?.html}
            description={_data?.cta_description?.text}
            imageSRC={_data?.cta_image?.url}
            imageAlt={_data?.cta_image?.alt}
            imageWidth={_data?.cta_image?.dimensions?.width}
            imageHeight={_data?.cta_image?.dimensions?.height}
            videoSRC={_data?.cta_video?.url}
            isReverse={true}
            className="outlinerFlex_card_dark_bg"
            location={location}
          />
        </article>
      </section>
    </Layout>
  );
};

export default OutlierDetectionActive;

export const query = graphql`
  query PrismicOutlierDetectionEncordActivePage($uid: String!) {
    prismicOutlierDetectionEncordActivePage(uid: { eq: $uid }) {
      uid
      data {
        body {
          ... on PrismicOutlierDetectionEncordActivePageDataBodyReviews {
            id
            slice_type
            primary {
              section_description {
                text
              }
              section_heading {
                text
              }
              section_label {
                text
              }
              section_link {
                url
              }
            }
            items {
              review_author {
                text
              }
              review_description {
                text
              }
              review_heading {
                text
              }
            }
          }
          ... on PrismicOutlierDetectionEncordActivePageDataBodyRepeatableSection {
            id
            slice_type
            items {
              learn_more_link {
                url
              }
              learn_more_text {
                text
              }
              section_description {
                text
              }
              section_heading {
                text
              }
              section_image {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              section_label {
                text
              }
              section_video {
                url
              }
            }
            primary {
              button_link {
                url
              }
              button_text {
                text
              }
              label_icon {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_description {
                text
              }
              section_heading {
                text
              }
              section_label {
                text
              }
            }
          }
          ... on PrismicOutlierDetectionEncordActivePageDataBodyCaseStudySection {
            id
            slice_type
            items {
              card_label_icon {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              card_label_text {
                text
              }
              section_description {
                text
              }
              section_heading {
                text
              }
            }
            primary {
              button_link {
                url
              }
              button_text {
                text
              }
              card_button_text {
                text
              }
              card_description {
                text
              }
              card_icon {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_heading {
                text
              }
              section_label {
                text
              }
            }
          }
        }
        cta_description {
          text
        }
        cta_heading {
          text
          html
        }
        cta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        hero_image {
          alt
          url
          dimensions {
            height
            width
          }
        }
        hero_video {
          url
        }
        meta_description {
          text
        }
        meta_heading {
          text
        }
        page_description {
          text
        }
        page_heading {
          html
          text
        }
      }
    }
    allPrismicTrustedBrandsLogos(
      filter: {
        uid: { in: ["outlier_detection_page_logos", "home-page-logos"] }
      }
    ) {
      nodes {
        uid
        data {
          body {
            ... on PrismicTrustedBrandsLogosDataBodyTrustedBrandsList {
              id
              items {
                brand_image {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url
                }
              }
              primary {
                label {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.prismicOutlierDetectionEncordActivePage?.data || {};

  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};
